import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    API_RESOURCE_LOCATION,
    API_RESOURCE_PRODUCT,
    API_RESOURCE_OFFER,
} from 'Consts/apiResources';

import { parseQueryToObject } from 'Utils/querystring';
import { getActivePromoCodeInLocation } from 'Utils/promoCode';

import Spinner from 'Components/layout/Spinner';
import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import TrainingSelectForm from 'Components/public/reservations/SlotSelectForm';
import CheckoutForm from 'Components/public/reservations/CheckoutForm';
import SelectProduct from 'Components/public/reservations/SelectProduct';
import Button from 'Components/layout/Button';

export default class PublicReservationsRehabSobieski extends Component {
    productSpecializationSlug = 'rehabilitacja';
    locationSlug = 'sobieski';
    offerSlug = 'radisson-rehabilitacja-offline';

    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            getLocationBySlug: PropTypes.func.isRequired,
            singleOfferBySlug: PropTypes.func.isRequired,
            singleProduct: PropTypes.func.isRequired,
            singleShopBySlug: PropTypes.func.isRequired,
        }).isRequired,
        shopPage: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};

    state = {
        selectedLocation: null,
        selectedOffer: null,
        selectedProduct: null,
        selectedSlot: null,
        formState: null,
    }

    componentDidMount = () => {
        const { location } = this.props;
        const { search } = location;

        this.loadLocation();
        this.loadOffer();
        this.loadShop();

        const queryObject = parseQueryToObject(search);
        if (queryObject.productId) {
            this.loadProduct(queryObject.productId);
        }
    }

    loadProduct = (id) => {
        const { actions } = this.props;

        actions.singleProduct({ id })
            .then(response => {
                this.setState({ selectedProduct: response.payload[API_RESOURCE_PRODUCT] });
            });
    }

    loadOffer = () => {
        const { actions } = this.props;

        actions.singleOfferBySlug({ slug: this.offerSlug })
            .then(response => {
                this.setState({ selectedOffer: response.payload[API_RESOURCE_OFFER] });
            });
    }

    loadShop = () => {
        const { actions } = this.props;

        actions.singleShopBySlug({
            slug: this.locationSlug
        });
    }

    loadLocation = () => {
        const { actions } = this.props;

        actions.getLocationBySlug({ slug: this.locationSlug })
            .then(response => {
                const location = response.payload[API_RESOURCE_LOCATION];
                const discountCode = getActivePromoCodeInLocation(location);

                this.setState(prevState => ({
                    ...prevState,
                    selectedLocation: location,
                    formState: {
                        ...prevState.formState,
                        comment: discountCode,
                    },
                }));
            });
    }

    onTrainingSelectFormSubmit = data => {
        this.setState({ selectedSlot: data });
    }

    onResetTraining = () => {
        this.setState({
            selectedSlot: null,
        });
    }

    onFormStateChange = (formState) => {
        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...formState,
            },
        }));
    }

    render() {
        const { location, history, shopPage } = this.props;
        const { selectedOffer, selectedProduct, selectedLocation, selectedSlot, formState } = this.state;
       
        if (!selectedOffer || !selectedLocation || !shopPage?.isLoaded) {
            return (
                <Spinner />
            );
        }

        return (
            <StyledComponent
                className={classnames({
                    'component-reservations-rehab-sobieski': true,
                })}
                styles={require('./styles')}
            >
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />
                
                <LayoutContainer>
                    {!selectedProduct && (
                        <SelectProduct
                            location={location}
                            history={history}
                            predefinedQuery={{
                                specializationSlug: this.productSpecializationSlug,
                            }}
                            selectedLocation={selectedLocation}
                            onSelect={(selectedProduct) => { this.setState({ selectedProduct }); }}
                        />
                    )}

                    {!selectedSlot && Boolean(selectedProduct) && Boolean(selectedOffer) && (
                        <div className="content content-training-select">
                            <div className="body">
                                <div className="training-select-container">
                                    <TrainingSelectForm
                                        location={location}
                                        history={history}
                                        selectedLocation={selectedLocation}
                                        selectedProduct={selectedProduct}
                                        onSubmit={this.onTrainingSelectFormSubmit}
                                        lagValue={shopPage?.data?.lagValue}
                                        lagType={shopPage?.data?.lagType}
                                    />
                                </div>
                                <div className="footer">
                                    <Button
                                        className="back-button"
                                        onClick={() => this.setState({
                                            selectedProduct: null,
                                        })}
                                        style='hollow'
                                    >
                                        Cofnij
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}

                    {Boolean(selectedProduct) && Boolean(selectedOffer) && Boolean(selectedSlot) && (
                        <div className="content content-checkout">
                            <div className="body">
                                <div className="checkout-container">
                                    <CheckoutForm
                                        location={location}
                                        history={history}
                                        onResetTraining={this.onResetTraining}
                                        onFormStateChange={this.onFormStateChange}
                                        data={formState}
                                        showPromoCode={true}
                                        selectedOffer={selectedOffer}
                                        selectedLocation={selectedLocation}
                                        selectedProduct={selectedProduct}
                                        selectedSlot={selectedSlot.slot}
                                        phoneNumberRequired={false}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                </LayoutContainer>
            </StyledComponent>
        );
    }
}
